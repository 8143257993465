@use 'libs/mds/styles' as mds;
@include mds.m-init-theme(pink);
@import '@angular/cdk/overlay-prebuilt';

// base reset styles
*,
::after,
::before,
input[type='search'] {
  box-sizing: border-box;
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

a.disabled {
  cursor: default;
  pointer-events: none;
}

html {
  font-size: 6.25%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.42857143;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[hidden] {
  display: none !important;
}
